import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRouteSnapshot, Data, NavigationEnd, Route, Router, RouterLink } from '@angular/router';

import { BehaviorSubject, filter } from 'rxjs';

import { BreadcrumbsComponent as GovukBreadcrumbsComponent, LinkDirective } from '@netz/govuk-components';

import { getActiveRoute } from '../navigation.util';
import { BREADCRUMB_ITEMS } from './breadcrumbs.factory';
import { BreadcrumbItem, RouteBreadcrumb } from './breadcrumbs.interface';

@Component({
  selector: 'netz-breadcrumbs',
  standalone: true,
  template: `
    <govuk-breadcrumbs *ngIf="breadcrumbs$ | async as breadcrumbs" [inverse]="inverse">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
        <a
          *ngIf="breadcrumb.link; else bareText"
          govukLink="breadcrumb"
          [routerLink]="breadcrumb.link"
          [queryParams]="breadcrumb.queryParams"
          >{{ breadcrumb.text }}</a
        >
        <ng-template #bareText>
          <li class="govuk-breadcrumbs__list-item" govukLink="breadcrumb">{{ breadcrumb.text }}</li>
        </ng-template>
      </ng-container>
    </govuk-breadcrumbs>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [GovukBreadcrumbsComponent, AsyncPipe, NgIf, LinkDirective, RouterLink, NgFor],
})
export class BreadcrumbsComponent {
  @Input() inverse = false;

  constructor(
    readonly router: Router,
    private readonly destroy$: DestroyRef,
    @Inject(BREADCRUMB_ITEMS) protected breadcrumbs$: BehaviorSubject<BreadcrumbItem[]>,
  ) {
    router.events
      .pipe(
        takeUntilDestroyed(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        const root = router.routerState.snapshot.root;
        const activeRoute = getActiveRoute(router, true);

        if (this.hasBreadcrumb(activeRoute.data)) {
          const breadcrumbs: BreadcrumbItem[] = [];
          this.addBreadcrumb(root, [], breadcrumbs);
          this.breadcrumbs$.next(breadcrumbs);
        } else {
          this.breadcrumbs$.next(null);
        }
      });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: BreadcrumbItem[]): void {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

      if (route.data.breadcrumb) {
        const breadcrumb: BreadcrumbItem = {
          text: this.getBreadcrumbText(route.data, route.title),
          link: this.getBreadcrumbLink(route, routeUrl),
          queryParams: route.queryParams ?? {},
        };

        if (!this.alreadyHasBreadcrumb(breadcrumbs, breadcrumb)) {
          breadcrumbs.push(breadcrumb);
        }
      }

      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getBreadcrumbText(data: Data, title?: string): string {
    const breadcrumb = data.breadcrumb;

    return this.hasTextResolutionFunction(breadcrumb)
      ? breadcrumb.resolveText(data)
      : typeof breadcrumb === 'function'
        ? breadcrumb(data)
        : typeof breadcrumb === 'boolean'
          ? (data.pageTitle ?? title)
          : breadcrumb;
  }

  private getBreadcrumbLink(route: ActivatedRouteSnapshot, routeUrl: string[]): string[] {
    let currentRoute = route;
    let currentRouteUrl = routeUrl;

    if (
      (!this.hasComponent(route) || this.mustSkipLink(currentRoute.data)) &&
      this.mustSkipLink(currentRoute.data) !== false
    ) {
      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;

        if (currentRoute.routeConfig.data?.breadcrumb) {
          break;
        }

        if (this.hasComponent(currentRoute)) {
          currentRouteUrl = currentRouteUrl.concat(currentRoute.url.map((url) => url.path));
          break;
        }

        currentRouteUrl = currentRouteUrl.concat(currentRoute.url.map((url) => url.path));
      }
    }

    return this.hasMoreUrlSegments(route) ? ['/' + currentRouteUrl.join('/')] : null;
  }

  private alreadyHasBreadcrumb(breadcrumbs: BreadcrumbItem[], breadcrumb: BreadcrumbItem): boolean {
    return breadcrumbs.map((b) => b.text).includes(breadcrumb.text);
  }

  private hasMoreUrlSegments(route: ActivatedRouteSnapshot): boolean {
    let remainingUrl = '';
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      remainingUrl = [remainingUrl, currentRoute.url.join('')].join('');
    }

    return remainingUrl.length > 0;
  }

  private hasComponent(route: ActivatedRouteSnapshot): boolean {
    const children = route.routeConfig.children;
    const hasChildren = children?.length > 0;
    const childWithEmptyPath: Route = hasChildren && children.find((child) => child.path.length === 0);

    return (!!route.routeConfig.component && !hasChildren) || !!childWithEmptyPath?.component;
  }

  private hasTextResolutionFunction(breadcrumb: any): boolean {
    return (
      typeof breadcrumb === 'object' && 'resolveText' in breadcrumb && typeof breadcrumb.resolveText === 'function'
    );
  }

  private mustSkipLink(data: Data): boolean {
    return (
      typeof data.breadcrumb === 'object' &&
      'skipLink' in data.breadcrumb &&
      ((typeof data.breadcrumb.skipLink === 'boolean' && data.breadcrumb.skipLink === true) ||
        (typeof data.breadcrumb.skipLink === 'function' && data.breadcrumb.skipLink(data) === true))
    );
  }

  private hasBreadcrumb(routeData: Data): boolean {
    if (!routeData) return false;
    const breacrumb: RouteBreadcrumb = routeData.breadcrumb;
    if (typeof breacrumb === 'boolean' || typeof breacrumb === 'string') return !!breacrumb;
    if (typeof breacrumb === 'function') return !!breacrumb(routeData);
    if (typeof breacrumb === 'object' && 'resolveText' in breacrumb && typeof breacrumb.resolveText === 'function')
      return !!breacrumb.resolveText(routeData);
  }
}
